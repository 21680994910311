//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-368:_1088,_3628,_7696,_1412,_5480,_6672,_6924,_9564;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-368')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-368', "_1088,_3628,_7696,_1412,_5480,_6672,_6924,_9564");
        }
      }catch (ex) {
        console.error(ex);
      }